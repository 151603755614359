import { HttpMethods, HttpStatusCodes, UnexpectedError, makeRequest } from '@benefits/http-client';

import { UserSessionData } from '../domain';
import { BASE_URL, endpoints } from './endpoints';

const switchGroup = async () => {
  const response = await makeRequest<UserSessionData>({
    baseURL: BASE_URL,
    method: HttpMethods.GET,
    url: endpoints.v1.switchGroup.get,
  });

  switch (response?.status) {
    case HttpStatusCodes.OK:
    case HttpStatusCodes.NO_CONTENT:
      return response.body;

    default:
      throw new UnexpectedError(response?.error);
  }
};

export { switchGroup };
