import { Heading1 } from '@benefits/pomodoro-components';
import styled from 'styled-components';

import { Image as ImageComponent } from '../../components/Image/Image';

export const Background = styled.section`
  background-color: #fdf8f1;
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 70px 40px 0;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointMdMaxWidth}) {
    width: 100%;
    padding: 0px;
    max-height: 160px;
  }
`;

export const Title = styled(Heading1)`
  font-size: 42px;

  & span {
    font-weight: 300;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointMdMaxWidth}) {
    display: none;
  }
`;

export const Image = styled(ImageComponent)`
  align-self: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointMdMaxWidth}) {
    width: 250px;
  }
`;

export const Wrapper = styled.section`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 70px 80px 70px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.breakpointLgMinWidth}) {
    position: relative;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointLgMaxWidth}) {
    width: 50%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointMdMaxWidth}) {
    width: 100%;
    height: 70%;
    padding: 50px 30px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointSmMaxWidth}) {
    height: auto;
  }
`;

export const AuthWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointMdMaxWidth}) {
    height: auto;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
