import { HttpMethods, HttpStatusCodes, UnexpectedError, makeRequest } from '@benefits/http-client';

import { ZendeskTokenResponse } from '../domain';
import { BASE_URL_PORTAL, endpoints } from './endpoints';

export const getZendeskToken = async () => {
  const response = await makeRequest<ZendeskTokenResponse>({
    baseURL: BASE_URL_PORTAL,
    method: HttpMethods.GET,
    url: endpoints.v1.zendesk.get,
  });

  switch (response?.status) {
    case HttpStatusCodes.OK:
      return response.body;
    case HttpStatusCodes.BAD_REQUEST:
    default:
      throw new UnexpectedError(response?.error);
  }
};
