import { HttpMethods, HttpStatusCodes, makeRequest } from '@benefits/http-client';

import { BASE_URL, endpoints } from './endpoints';

type ValidationTokenNewPasswordResponseType = {
  status: boolean;
};

const validateTokenNewPassword = async (token: string) => {
  const response = await makeRequest<ValidationTokenNewPasswordResponseType>({
    baseURL: BASE_URL,
    method: HttpMethods.GET,
    url: `${endpoints.v1.recoveryPassword.get}?token=${token}`,
  });

  return response?.status === HttpStatusCodes.OK && response?.body?.status;
};

export { validateTokenNewPassword };
