import { useContext } from 'react';

import * as featureToggles from '../../toggle/featureToggles';
import { ToggleContext } from '../../toggle/ToggleStore';

export const useAuth2faNXP = () => {
  const featureTogglesContent = useContext(ToggleContext);

  const isAuth2faEnabled = featureTogglesContent?.isEnable(featureToggles.AUTH_2FA_NXP_ENABLED);

  return {
    isAuth2faEnabled,
  };
};
