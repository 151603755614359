type GetUserAuthenticated = () => {
  accessToken: string;
  email: string;
  group: {
    id: string;
    name: string;
  };
  userName: string;
};

export const getUserAuthenticated: GetUserAuthenticated = () => {
  const user = localStorage.getItem('_ifood_companies_user');

  return user ? JSON.parse(user) : null;
};
