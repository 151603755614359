import { useQuery } from '@benefits/http-client';

import { UserSessionData } from '../../domain';
import { switchGroup } from '../../services';

type useSwitchGroupParamsType = {
  onSuccess?: (data: UserSessionData) => void;
  onError?: (error: Error) => void;
  enabled: boolean;
};

export const useSwitchGroup = ({ onSuccess, onError, enabled }: useSwitchGroupParamsType) => {
  const request = async () => await switchGroup();
  const { refetch } = useQuery(['switch-group'], request, {
    onSuccess,
    onError,
    enabled,
    retry: false,
  });

  return { refetch };
};
