import { FC } from 'react';

import { AuthWrapper, Background, Image, Title, Wrapper } from './Auth.styles';

const Auth: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <AuthWrapper>
      <Background>
        <Title m={0} variant="large">
          Múltiplos benefícios <br />
          <span>em um só cartão</span>
        </Title>

        <Image
          alt="Múltiplos benefícios em um só cartão"
          height={600}
          priority={true}
          src="/illustrations/webps/woman_with_card.webp"
          width={600}
        />
      </Background>

      <Wrapper>{children}</Wrapper>
    </AuthWrapper>
  );
};

export { Auth };
