import { Heading, Text } from '@benefits/components';
import { Illustrations } from '@benefits/crafts';

import { Wrapper } from './Maintenance.styles';

export const MaintenanceMode: React.FC = () => {
  return (
    <Wrapper>
      <Illustrations.Balloon />
      <Heading component="h1" style={{ fontWeight: 500 }} variant="heading4">
        No momento, estamos em pausa pra manutenção
      </Heading>
      <Text weight="bold">Algumas melhorias estão a caminho! Em breve, a plataforma estará disponível novamente.</Text>
    </Wrapper>
  );
};
